<script>
import {useAuthorization} from "../js/authorization";

export default {
  name: "LabyModAuth",
  data() {
    const {authorized, checkAuthorization, changeAuthorization} = useAuthorization(false);

    return {
      authorized: authorized,
      checkAuthorization: checkAuthorization,
      changeAuthorization: changeAuthorization,
    }
  },
  mounted() {
    const code = this.$route.query.code;
    if (code === undefined) {
      console.log("undefined code")
      this.$router.push({path: "/dashboard"});
      return;
    }
    fetch("https://api.globalchataddon.de/dashboard/labymod/oauth", {
      method: "POST",
      headers: {
        code: code
      }
    }).then((response) => {
      if (response.status === 200) {
        response.text().then((text) => {
          this.changeAuthorization(true);
          this.$cookies.set("GlobalChat-Login", text, -1, "/", ".globalchataddon.de");
          this.$router.push({path: "/dashboard"});
        });
      } else {
        this.infoMessage = "Invalid register token";
      }
    });
  }
};
</script>